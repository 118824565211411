/* You can add global styles to this file, and also import other style files */
@import 'src/style/variables';
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Lato:300,400');
@import '~bootstrap/dist/css/bootstrap.min.css';

html {
    overflow-x: hidden;
}

h2 {
    font-size: 27px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.89;
    letter-spacing: 1.3px;
    color: $color-grey;
}

h3 {
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 1.3px;
    color: $color-grey;
}

a {
    color: $color-main;
}

textarea,
select,
input,
button {
    outline: none;
}

button {
    background-color: transparent;
    display: block;
    position: relative;
    padding: unset;
}

.desktop-only {
    display: none;
}

.btn {
    display: inline-block;
    text-decoration: none;
    color: white;
    background-color: $color-secondary;
    background-image: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.7px;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
    vertical-align: middle;
    padding: 14px;
    min-width: 150px;
    width: auto;
    outline: 0;
    border: none;
    border-radius: 8px;
    -webkit-tap-highlight-color: transparent;
    box-shadow: 0 12px 22px 0 rgba(0, 0, 0, 0.24);
    -webkit-box-shadow: 0 12px 22px 0 rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 12px 22px 0 rgba(0, 0, 0, 0.24);
    transition: 0.2s ease-out;

    &.btn-default {
        background-color: $color-background-disable;
    }

    &[disabled],
    &[disabled]:hover {
        background-color: $color-background-disable;
        color: $color-secondary;
        border: solid 1px;
        border-color: $color-secondary;
    }

    &:active,
    &:hover {
        background-color: #67777e;
        color: white;
        border-color: $color-secondary;
        border: solid 1px;
    }

    &:focus {
        border-color: $color-secondary;
        border: solid 1px;
        -webkit-box-shadow: 0px 0px 0px 1px rgb(0 0 0);
        -moz-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 1);
        box-shadow: 0px 0px 0px 1px rgb(0 0 0);
    }

    &.btn-secondary {
        background-color: transparent;
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
    }
}
.close {
    position: absolute;
    right: 5px;
    top: 2px;
    cursor: pointer;
    padding: 0;
    border: none;
    color: $color-main;
}

form {
    .form-group {
        label {
            font-family: Lato;
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.17;
            letter-spacing: 0.6px;
            color: $color-grey;
        }

        input {
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 14px;
            letter-spacing: 0.6px;
            color: $color-grey;
            width: calc(100% - 32px);
            padding: 15px;
            border: 1px solid $color-grey;

            &.ng-invalid.ng-touched {
                border: 1px solid $color-secondary;
            }
        }
    }

    .btn {
        display: block;
        margin: 40px auto 15px;
    }
}

body {
    background: none;
    background-color: $color-background;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 13px;
    line-height: 1.66666667;
    color: #363636;
    margin: 0px;
    overflow-x: hidden;

    #header {
        background-color: $color-header-background;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 0;
        position: relative;
        z-index: 100;

        .header-wrapper {
            background-color: $color-header-background;
            max-width: 768px;
            margin: auto;
            color: white;
            min-height: 55px;
            padding: 0 10px;
            text-transform: none;
            letter-spacing: 1px;
            line-height: normal;
            font-size: 16px;
            font-weight: bold;
            white-space: normal;
            box-shadow: 2px 2px 6px 0px rgba(3, 3, 3, 0.25);

            img.logo {
                height: 55px;
                float: left;
                box-shadow: 0px 0px 6px 2px rgba(3, 3, 3, 0.25);
            }

            h1 {
                margin: 0;
                font-size: 14px;
                padding: 25px 0 10px 65px;
                text-align: center;
                font-weight: bold;
            }

            .description {
                display: none;
            }
        }
    }

    #content {
        position: relative;
        background-color: #fff;
        min-height: 500px;

        #mainMenu {
            padding: 20px 20px 0 20px;

            #backLink {
                line-height: 2.5em;
            }
        }

        #mainSection {
            padding: 20px 20px 30px 20px;
            clear: both;

            .page-image {
                max-width: 100%;
                max-height: 215px;
                margin-bottom: 30px;
            }

            .intro {
                text-align: center;
                font-weight: bold;
                padding-bottom: 10px;
                padding-top: 10px;
                border-bottom: 1px dotted;
                border-top: 1px dotted;
                margin-bottom: 40px;
            }
        }
    }

    #mainMenu {
        a {
            text-decoration: none;
        }
    }

    div.gdi-modal {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        background-color: rgba(210, 210, 210, 0.8);
        z-index: 50;
        display: flex;
        justify-content: center;
        align-items: center;

        .gdi-modal-wrapper {
            box-shadow: 2px 2px 6px 0px rgba(3, 3, 3, 0.25);
            border-radius: 5px;
            border: none;
            background: white;
            padding: 15px 15px 25px;
            position: fixed;
            width: 98%;
            //left: 5%;
            //width: 82%;
            top: 120px;
            text-align: center;
            color: $color-dark-grey;
        }
    }
}

@media (min-width: $layout-mobile) {
    .desktop-only {
        display: block;
    }

    .mobile-only {
        display: none;
    }

    body {
        #header {
            min-width: 768px;
            max-width: 768px;
            margin: 30px auto 20px;
            background-color: transparent;

            .header-wrapper {
                padding: 20px 100px;
                min-height: 80px;
                position: relative;
                box-shadow: 2px 2px 6px 0px rgba(3, 3, 3, 0.25);

                img.logo {
                    position: absolute;
                    height: 80px;
                    left: 20px;
                }

                h1 {
                    font-size: 18px;
                    padding: 10px 10px 0;
                    margin-left: 70px;
                }

                .description {
                    display: block;
                    margin: 10px 0 0 70px;
                    font-size: 14px;
                    text-align: center;
                }
            }
        }

        #content {
            padding-top: 50px;
            min-width: 768px;
            max-width: 768px;
            margin: 0 auto 30px;
            padding: 0 0 20px;
            box-shadow: 2px 2px 6px 0px rgba(3, 3, 3, 0.25);
        }

        div.gdi-modal {
            .gdi-modal-wrapper {
                width: 600px;
                //left: 50%;
                //margin-left: -260px;
                position: absolute;
                margin-top: 40px;
                margin-left: unset;
                left: auto;
            }
        }
    }
}

// Utility classes
.pointer {
    cursor: pointer;
}

.error {
    color: $color-error-message;
}

.d-flex {
    display: flex;
}

.flex-row {
    flex-direction: row;
}

.flex-column {
    flex-direction: column;
}

.justify-content-around {
    justify-content: space-around;
}

.justify-content-between {
    justify-content: space-between;
}

.align-items-center {
    align-items: center;
}

.align-items-baseline {
    align-items: baseline;
}

.align-items-start {
    align-items: flex-start;
}

.text-align-center {
    text-align: center;
}

.centered-element {
    display: block;
    margin: 0 auto;
}

.word-break-break-word {
    word-break: break-word;
}

.grey-text {
    color: $color-dark-grey;
}

.font-size-15 {
    font-size: 15px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 2px solid $color-disable;
    margin: 1em 0;
    padding: 0;
}

.separator {
    width: 20px;
    margin: 0 auto;
}

.uppercase {
    text-transform: uppercase;
}

.sm-text {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: 0.7px;
    color: $color-grey;
}

.sm-md-text {
    font-size: 21px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: $color-grey;
}

.md-text {
    font-size: 27px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.89;
    letter-spacing: 1.3px;
    color: $color-grey;
}

.lg-text {
    font-size: 36px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.7px;
    color: $color-grey;
}

.loader {
    width: 53px;
    height: 53px;
    margin: 30px;
}

.rotate {
    -webkit-animation: spin 3s linear infinite;
    -moz-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
}
@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

noscript {
    padding: 10px;
    font-size: 18px;
}

.selection {
    border: solid 2px;
    -webkit-box-shadow: 0px 0px 0px 1px rgb(0 0 0);
    -moz-box-shadow: 0px 0px 0px 1px #000000;
    box-shadow: 0px 0px 0px 1px rgb(0 0 0);
    z-index: 1;
    outline: unset;
}

.link-custom-hover:hover,
.link-custom-focus:focus {
    text-decoration: none;
    color: $color-main;
    $border: 2px;
    z-index: 1;
    outline: unset;
    color: $color-black-opa;

    &:before {
        content: '';
        position: absolute;
        top: -$border;
        right: -$border;
        bottom: -$border;
        left: -$border;
        z-index: -1;
        border-radius: inherit; /* !importanté */
        background: linear-gradient(90deg, #000000 0%, #404040 52%, #808080 100%);
        display: block;
    }
}

a:hover,
button:hover {
    color: $color-black-opa;
}

a[disabled='disabled'] {
    color: #8b8d8f;
    cursor: not-allowed;
    text-decoration: none;
}

a[disabled='disabled']:hover,
a[disabled='disabled']:focus {
    color: #8b8d8f !important;
}

.occ-btn-secondary {
    color: #404040;
    background-color: #f1f1f1;
}

.occ-btn-secondary:hover,
.occ-btn-secondary:focus,
.occ-btn-secondary:active,
.occ-btn-secondary.active {
    color: #3c3c3c;
    background-color: #bebebe;
}

legend {
    font-size: 16px !important;
}
