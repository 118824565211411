/* responsive */
$layout-mobile: 768px;

/* Color */
$color-secondary: #2d3940;
$color-black: #000;
$color-green: #228b22;
$color-grey: #404040;
$color-dark-grey: #2c3940;
$color-light-grey: #eeeeee;
$color-shiny-grey: #999999;
$color-background: #e2e2e2;
$color-creme: #cbcbcb;
$color-red: #cc0000;

$color-main: $color-grey;
$color-header-background: $color-dark-grey;
$color-disable: $color-shiny-grey;
$color-background-disable: $color-background;
$color-entity-list-siret: $color-creme;
$color-error-message: $color-red;
$color-picture-credit: $color-main;
$color-logout-back: $color-red;
$color-sitemap-link: $color-red;

/* Color opacity */
$color-secondary-opa: rgba(45, 57, 64, 0.75);
$color-black-opa: rgba(0, 0, 0, 0.75);
$color-green-opa: rgba(34, 139, 34, 0.75);
$color-grey-opa: rgba(64, 64, 64, 0.75);
$color-dark-grey-opa: rgba(44, 57, 64, 0.75);
$color-light-grey-opa: rgba(238, 238, 238, 0.75);
$color-shiny-grey-opa: rgba(153, 153, 153, 0.75);
$color-background-opa: rgba(226, 226, 226, 0.75);
$color-creme-opa: rgba(203, 203, 203, 0.75);
$color-red-opa: rgba(204, 0, 0, 0.75);

$color-main-opa: $color-grey-opa;
$color-header-background-opa: $color-dark-grey-opa;
$color-disable-opa: $color-shiny-grey-opa;
$color-background-disable-opa: $color-background-opa;
$color-entity-list-siret-opa: $color-creme-opa;
$color-error-message-opa: $color-red-opa;
$color-picture-credit-opa: $color-main-opa;
$color-logout-back-opa: $color-red-opa;
$color-sitemap-link-opa: $color-red-opa;

/* Color opacity IE */
$color-secondary-opa-ie: #bf2d3940;
$color-black-opa-ie: #bf000000;
$color-green-opa-ie: #bf228b22;
$color-grey-opa-ie: #bf404040;
$color-dark-grey-opa-ie: #bf2c3940;
$color-light-grey-opa-ie: #bfeeeeee;
$color-shiny-grey-opa-ie: #bf999999;
$color-background-opa-ie: #bfe2e2e2;
$color-creme-opa-ie: #bfcbcbcb;
$color-red-opa-ie: #bfcc0000;

$color-main-opa-ie: $color-grey-opa-ie;
$color-header-background-opa-ie: $color-dark-grey-opa-ie;
$color-disable-opa-ie: $color-shiny-grey-opa-ie;
$color-background-disable-opa-ie: $color-background-opa-ie;
$color-entity-list-siret-opa-ie: $color-creme-opa-ie;
$color-error-message-opa-ie: $color-red-opa-ie;
$color-picture-credit-opa-ie: $color-main-opa-ie;
$color-logout-back-opa-ie: $color-red-opa-ie;
$color-sitemap-link-opa-ie: $color-red-opa-ie;
